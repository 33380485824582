import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Oval } from 'react-loader-spinner';
import SVG from 'react-inlinesvg';
import DatePicker from 'react-datepicker';

import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import { IDeviceTypeEnum, IFlavorEnum, INicotineBrandEnum, IQr } from 'entities/qr';
import { ICampaign } from 'entities/campaign';

import Text from 'components/Text';
import Button from 'components/Button';

import icons from 'assets/icons';
import Toggle from 'components/Toggle';
import { IGetCampaignReport, IGetCampaignReportParams } from 'redux/slices/reports/types';
import Utils from 'lib/Utils';
import styled, { keyframes } from 'styled-components';
import Colors from 'assets/themes/Colors';
import { IAverageCompletedScansByHourAndDay } from 'api/ReportsBase';
import QrCard from './components/QrCard';
import DeleteQrModal from './components/DeleteQrModal';
import SidebarFilter from './components/SidebarFilter';
import LineGraph from './components/LineGraph';
import BarGraph from './components/BarGraph';

import { ContainerStyles, ItemStyles, PreFilterButtons } from './styles/QrScreenStyles';
import QrDetailsStyles from './styles/QrDetailsStyles';
import TimeUsersCompleteSurveysBarGraph from './components/TimeUsersCompleteSurveysBarGraph';

interface INameValuePair {
    name: string;
    value: number;
}

interface StageDropoff {
    [stage: string]: number;
    value: number;
  }

interface IStageDropOff {
    name: string;
    percent: number;
    value: number;
  }

interface IScansByDevice {
    type: IDeviceTypeEnum;
    scans: number;
    percent: number;
}
interface IScansByCities {
    name: string;
    scans: number;
    percent: number;
}

interface QrScreenProps {
    qrListLoading: boolean;
    qrListError: string;
    qrList: IQr[];
    campaignList: ICampaign[];
    startDate: string | null;
    endDate: string | null;
    getAllQrs: (campaignId: string) => void;
    createNewQrLoading: boolean;
    createNewQr: (campaignId: string) => void;
    resetQrDetails: () => void;
    resetQrSurveys: () => void;
    resetUniqueCodes: () => void;
    resetSurveyData: () => void;
    isFilterSidebarOpen: boolean;
    setFilterSidebarOpen: (state: boolean) => void;
    setStartDate: (date: string | null) => void;
    setEndDate: (date: string | null) => void;
    campaignReport: IGetCampaignReport;
    getCampaignReportAttempt: boolean;
    getCampaignReportError: string;
    getCampaignReport: (data: IGetCampaignReportParams) => void;
    uniqueUser: boolean;
    setUniqueUser: (state: boolean) => void;
    clearFilterByFlavour: () => void;
    getFilterByFlavour: number[] | null;
}

const QrScreen: FunctionComponent<QrScreenProps> = (props: QrScreenProps) => {
    const {
        qrListLoading,
        qrListError,
        qrList,
        campaignList,
        startDate,
        endDate,
        getAllQrs,
        createNewQrLoading,
        createNewQr,
        resetQrDetails,
        resetQrSurveys,
        resetUniqueCodes,
        resetSurveyData,
        isFilterSidebarOpen,
        setFilterSidebarOpen,
        setStartDate,
        setEndDate,
        campaignReport,
        getCampaignReportAttempt,
        getCampaignReportError,
        getCampaignReport,
        uniqueUser,
        setUniqueUser,
        clearFilterByFlavour,
        getFilterByFlavour,
    } = props;

    const [screenName, setScreenName] = useState('');
    const [deleteParams, setDeleteParams] = useState({
        name: '',
        id: '',
    });

    const [preFilterMarlboro, setPreFilterMarlboro] = useState(false);
    const [preFilterOthers, setPreFilterOthers] = useState(false);
    const [averageTimeSpentDayData, setaverageTimeSpentDayData] = useState<INameValuePair[]>([]);
    const [dropOffData, setDropOffData] = useState<IStageDropOff[]>([]);
    const [scanByOS, setScanByOS] = useState<IScansByDevice[]>([]);
    const [scanByLocation, setScanByLocation] = useState<IScansByCities[]>([]);
    const [totalCompletedScansByDayData, setTotalCompletedScansByDayData] = useState<INameValuePair[]>([]);
    const [averageCompletedScansByHourAndDay, setAverageCompletedScansByHourAndDay] = useState<IAverageCompletedScansByHourAndDay[]>([]);

    const param = useParams();
    const { campaignId = '', campaignName = '' } = param;

    const dropOffTitle = [
        'Welcome Page',
        'Answering Survey',
    ];

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) setStartDate(dayjs(start).format('YYYY-MM-DD'));
        if (end) setEndDate(dayjs(end).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        getAllQrs(campaignId);
        resetQrDetails();
        resetQrSurveys();
        resetUniqueCodes();
        resetSurveyData();
        setScreenName(campaignName);
        setPreFilterMarlboro(false);
        setPreFilterOthers(false);
        setFilterSidebarOpen(false);
        setUniqueUser(false);
        Utils.GetBrands(INicotineBrandEnum.Others);
    }, []);

    // Set default date to the deployment date
    useEffect(() => {
        // Gets the first index of the qrList array since there could be multiple qr codes for each campaign
        if (qrList.length > 0 && qrList[0].deploymentDate) {
            const deployDate = dayjs(qrList[0].deploymentDate).format('YYYY-MM-DD');
            getCampaignReport({
                campaignId,
                uniqueUsers: uniqueUser,
                dateFrom: deployDate,
                dateTo: dayjs().format('YYYY-MM-DD'),
            });
            setStartDate(deployDate);
            setEndDate(dayjs().format('YYYY-MM-DD'));
        }
    }, [qrList]);

    useEffect(() => {
        if (startDate && endDate) {
            if (preFilterMarlboro) {
                getCampaignReport({
                    campaignId,
                    uniqueUsers: uniqueUser,
                    dateFrom: startDate,
                    dateTo: endDate,
                    flavourEnumString: Utils.GetBrands(INicotineBrandEnum.Marlboro).join(','),
                });
            } else if (preFilterOthers) {
                getCampaignReport({
                    campaignId,
                    uniqueUsers: uniqueUser,
                    dateFrom: startDate,
                    dateTo: endDate,
                    flavourEnumString: Utils.GetBrands(INicotineBrandEnum.Others).join(','),
                });
            } else if (getFilterByFlavour && getFilterByFlavour.length > 0) {
                getCampaignReport({
                    campaignId,
                    uniqueUsers: uniqueUser,
                    dateFrom: startDate,
                    dateTo: endDate,
                    flavourEnumString: getFilterByFlavour.join(','),
                });
            } else {
                getCampaignReport({
                    campaignId,
                    uniqueUsers: uniqueUser,
                    dateFrom: startDate,
                    dateTo: endDate,
                });
            }
        }
    }, [preFilterMarlboro, preFilterOthers, endDate, uniqueUser]);

    // useEffect(() => {
    //     if (campaignReport.averageTimeSpentDay.length > 0) {
    //         setaverageTimeSpentDayData(campaignReport.averageTimeSpentDay.map((avg) => {
    //             return {
    //                 name: avg.date,
    //                 value: avg.value,
    //             };
    //         }));
    //     } else {
    //         setaverageTimeSpentDayData([]);
    //     }
    // }, [campaignReport.averageTimeSpentDay]);

    useEffect(() => {
        if (Object.keys(campaignReport.stageDropoffs).length !== 0) {
            const stageDropOffData: IStageDropOff[] = campaignReport.stageDropoffs.filter((item, i) => (i === 0) || (i === campaignReport.stageDropoffs.length - 2)).map((obj: StageDropoff) => {
                const name: string = Object.keys(obj)[0]; // Extract the key (e.g., "201") from the object
                const percent: number = obj[name]; // Access the value corresponding to the key (e.g., 10)
                const { value, median } = obj; // Access the "value" property

                return {
                    name,
                    percent: Number((percent * 100).toFixed(2)),
                    value,
                    median,
                };
            });
            setDropOffData(stageDropOffData);
        } else {
            setDropOffData([]);
        }
    }, [campaignReport.stageDropoffs]);

    useEffect(() => {
        if (Object.keys(campaignReport.scansByOperatingSystem).length !== 0) {
            setScanByOS(campaignReport.scansByOperatingSystem);
        } else {
            setScanByOS([]);
        }
    }, [campaignReport.scansByOperatingSystem]);

    useEffect(() => {
        if (Object.keys(campaignReport.scansByLocation).length !== 0) {
            setScanByLocation(campaignReport.scansByLocation);
        } else {
            setScanByLocation([]);
        }
    }, [campaignReport.scansByLocation]);

    useEffect(() => {
        if (campaignReport.totalCompletedScansByDay.length > 0) {
            setTotalCompletedScansByDayData(campaignReport.totalCompletedScansByDay.map((data) => {
                return {
                    name: data.date,
                    value: data.value,
                };
            }));
        } else {
            setTotalCompletedScansByDayData([]);
        }
    }, [campaignReport.totalCompletedScansByDay]);

    useEffect(() => {
        if (campaignReport.averageCompletedScansByHourAndDay) {
            setAverageCompletedScansByHourAndDay(campaignReport.averageCompletedScansByHourAndDay.map((data) => {
                return {
                    hour: data.hour,
                    value: data.value,
                    percentage: data.percentage,
                };
            }));
        } else {
            setAverageCompletedScansByHourAndDay([]);
        }
    }, [campaignReport.averageCompletedScansByHourAndDay]);

    const renderQrCards = () => {
        if (!qrListLoading && !qrList.length) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '24px',
                    }}
                >
                    <Text>No QRs Created</Text>
                </div>
            );
        }

        return (
            <>
                {qrList.map(item => {
                    const { id, name, qrType, status, createdAt, numberOfScans } = item;

                    const formattedDate = dayjs(createdAt).format('MMM DD, YYYY');

                    return (
                        <QrCard
                            key={id}
                            id={id}
                            name={name}
                            type={qrType}
                            status={status}
                            createdAt={formattedDate}
                            numOfScans={numberOfScans}
                            passQrNameToDeleteModal={setDeleteParams}
                            campaignId={campaignId}
                        />
                    );
                })}
            </>
        );
    };

    const renderFilter = () => {
        if (!qrListLoading && !qrList.length) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '24px',
                    }}
                >
                    <Text>No Survey Data</Text>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', width: '320px', alignItems: 'center' }}>
                <Text css={ItemStyles.filterTitle}>
                    Showing From
                </Text>
                <div style={{ width: 'auto' }}>

                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        disabled={getCampaignReportAttempt}
                        selected={startDate ? new Date(startDate) : null}
                        startDate={startDate ? new Date(startDate) : null}
                        endDate={endDate ? new Date(endDate) : null}
                        selectsRange
                        maxDate={new Date()}
                        onChange={onDatesChange}
                        className='date-picker'
                        showWeekNumbers
                        allowSameDay
                    />
                </div>
            </div>
        );
    };

    const renderSubTitle = () => {
        return (
            <Text css={ItemStyles.divSubTitle}>
                {`${dayjs(startDate).format('DD MMMM YYYY')} - ${dayjs(endDate).format('DD MMMM YYYY')}`}
            </Text>
        );
    };

    const renderLineGraph = () => {
        return (
            <>
                <div>
                    <Text css={ItemStyles.divTitle}>
                        Average Time Spent
                    </Text>
                    {renderSubTitle()}
                </div>
                {(averageTimeSpentDayData.length > 0)
                    ? <LineGraph lineData={averageTimeSpentDayData} />
                    : <Text css={ItemStyles.noDataFound}>No data found</Text>}
            </>
        );
    };

    const renderBarGraphSubtitle = () => {
        return (
            <Text css={[ItemStyles.divSubTitle]}>
                Users that landed on each page
            </Text>
        );
    };

    const renderBarGraph = () => {
        return (
            <>
                <div>
                    <Text css={ItemStyles.divTitle}>
                        Conversion Rate Through Each Stages
                    </Text>
                    {renderBarGraphSubtitle()}
                    {renderSubTitle()}
                </div>
                {(dropOffData.length > 0)
                    ? <BarGraph barData={dropOffData} />
                    : <Text css={ItemStyles.noDataFound}>No data found</Text>}
                {
                    (dropOffData.length > 0)
                        ? (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingInline: '10px' }}>
                                {dropOffTitle.map((item, index) => {
                                    if (index === dropOffTitle.length - 1) {
                                        return (
                                            <Text css={ItemStyles.scanSubTitle} key={item}>
                                                {item}
                                            </Text>
                                        );
                                    }

                                    return (
                                        <>
                                            <Text css={ItemStyles.scanSubTitle} key={item}>
                                                {item}
                                            </Text>
                                            <SVG src={icons.ChevronRight} style={{ paddingTop: '5px' }} width='20px' height='20px' />
                                        </>
                                    );
                                })}
                            </div>
                        ) : ''
                }

            </>
        );
    };

    const renderPercentageStat = (value = 0, total = 0) => {
        let percent = '-';

        if (total && value) percent = `${Math.round((value / total) * 100)}%`;

        return (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div style={QrDetailsStyles.StatsCardValue}>
                    {percent}
                </div>

                <div style={{ marginLeft: '10px', fontSize: 15 }}>
                    (
                    {value}
                    /
                    {total}
                    )
                </div>
            </div>
        );
    };

    const renderStats = () => {
        return (
            <>
                <div style={ContainerStyles.stats}>
                    <Text css={ItemStyles.statTitle}>
                        {campaignReport.totalCampaignScans}
                    </Text>
                    <Text css={ItemStyles.statSubTitle}>
                        Total Scans
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    {renderPercentageStat(campaignReport.completeSurveySetPercentage.value, campaignReport.completeSurveySetPercentage.total)}
                    <Text css={ItemStyles.statSubTitle}>
                        Completed the survey set
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    <Text css={ItemStyles.statTitle}>
                        {campaignReport.completeSurveySetPercentage.value}
                    </Text>
                    <Text css={ItemStyles.statSubTitle}>
                        Total Survey Completion
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    {renderPercentageStat(campaignReport.averageCompletionRate.value, campaignReport.averageCompletionRate.total)}
                    <Text css={ItemStyles.statSubTitle}>
                        Average Completion Rate
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    <Text css={ItemStyles.statTitle}>
                        {campaignReport.medianTimeSpentInEntireSurvey}
                        s
                    </Text>
                    <Text css={ItemStyles.statSubTitle}>
                        Median Time Spent In Entire Survey
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    <Text css={ItemStyles.statTitle}>
                        {campaignReport.medianTimeSpentForEachQuestion}
                        s
                    </Text>
                    <Text css={ItemStyles.statSubTitle}>
                        Median Time Spent For Each Questions
                    </Text>
                </div>
                <div style={ContainerStyles.stats}>
                    <Text css={ItemStyles.statTitle}>
                        {campaignReport.disqualifiedUsers}
                    </Text>
                    <Text css={ItemStyles.statSubTitle}>
                        Disqualified Users
                    </Text>
                </div>
            </>
        );
    };

    const renderScanOS = () => {
        const osTableHeaders = [
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '20%' }}>OS</div>,
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '70%' }}>Scans</div>,
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '10%' }}>%</div>,
        ];
        return (
            <>
                <Text css={ItemStyles.scanTitle}>
                    Scans By Operating System
                </Text>
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                    <div style={{ marginTop: '10px' }}>
                        <div style={{ paddingBottom: '20px' }}>
                            <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingInline: '10px' }}>
                                {scanByOS.length ? osTableHeaders.map(item => item) : 'No records found'}
                            </div>

                            <div style={{ overflowY: 'auto', maxHeight: '230px' }}>
                                {scanByOS.map(item => {
                                    return (
                                        <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingInline: '10px' }}>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '20%' }}>{IDeviceTypeEnum[item.type]}</div>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, justifyContent: 'normal', width: '70%', alignItems: 'center' }}>
                                                <div
                                                    style={{
                                                        width: `${(item.percent * 300).toFixed(1)}px`,
                                                        height: '14px',
                                                        borderRadius: '7px',
                                                        background: '#0074C0',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                {item.scans}
                                            </div>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '10%' }}>
                                                {(item.percent * 100).toFixed(1)}
                                                %
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderScanCities = () => {
        const cityTableHeaders = [
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '10%' }}>#</div>,
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '55%' }}>City</div>,
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '20%' }}>Scans</div>,
            <div style={{ ...QrDetailsStyles.QrDetailsTableHeaderCells, width: '15%' }}>%</div>,
        ];
        return (
            <>
                <Text css={ItemStyles.scanTitle}>
                    Scans By Cities
                </Text>
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                    <div style={{ marginTop: '10px' }}>
                        <div style={{ paddingBottom: '20px' }}>
                            <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingInline: '10px' }}>
                                {scanByLocation.length ? cityTableHeaders.map(item => item) : 'No records found'}
                            </div>

                            <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                {scanByLocation.map((item, index) => {
                                    return (
                                        <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingInline: '10px' }}>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '10%' }}>{index + 1}</div>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '55%' }}>{item.name}</div>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '20%' }}>{item.scans}</div>
                                            <div style={{ ...QrDetailsStyles.QrDetailsTableDataCells, width: '15%' }}>
                                                {(item.percent * 100).toFixed(1)}
                                                %
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderTotalCompletedSurveyLineGraph = () => {
        return (
            <>
                <div>
                    <Text css={ItemStyles.divTitle}>
                        Total Completed Survey
                    </Text>
                    {renderSubTitle()}
                </div>
                {(totalCompletedScansByDayData.length > 0)
                    ? <LineGraph lineData={totalCompletedScansByDayData} graphName='totalCompletedScansByDay' />
                    : <Text css={ItemStyles.noDataFound}>No data found</Text>}

            </>
        );
    };

    const renderTimeUsersCompleteSurveysLineGraph = () => {
        return (
            <>
                <div>
                    <Text css={ItemStyles.divTitle}>
                        Time Users Complete Surveys
                    </Text>
                    {renderSubTitle()}
                </div>
                {(averageCompletedScansByHourAndDay.length > 0)
                    ? <TimeUsersCompleteSurveysBarGraph barData={averageCompletedScansByHourAndDay} />
                    : <Text css={ItemStyles.noDataFound}>No data found</Text>}

            </>
        );
    };

    if (qrListLoading || createNewQrLoading) {
        return (
            <div style={ContainerStyles.spinnerContainer}>
                <Oval
                    height={200}
                    width={200}
                    color='#1998dd'
                    secondaryColor='#A5AAB5'
                />
            </div>
        );
    }

    if (qrListError) {
        return (
            <div style={ContainerStyles.spinnerContainer}>
                <Text style={{ color: 'red', marginBottom: '20px' }}>{qrListError}</Text>
                <Button
                    onClick={() => getAllQrs(campaignId)}
                    css={ItemStyles.retryButton}
                >
                    Retry
                </Button>
            </div>
        );
    }

    return (
        <div style={ContainerStyles.mainContainer}>
            <div style={ContainerStyles.titleContainer}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text css={ItemStyles.pageTitle}>
                        {screenName}
                    </Text>
                    <Text css={ItemStyles.qrArrayLength}>
                        {`(${qrList.length})`}
                    </Text>
                </div>
            </div>
            <div style={ContainerStyles.topContainer}>
                <Text css={ItemStyles.sectionTitle}>
                    Campaign QR Codes
                </Text>

                <Button
                    onClick={() => createNewQr(campaignId)}
                    css={ItemStyles.newQrButton}
                >
                    <Text>Create New QR Code</Text>
                </Button>
            </div>
            <div style={ContainerStyles.qrCardsContainer}>
                {renderQrCards()}
            </div>

            <DeleteQrModal
                deleteParams={deleteParams}
                campaignId={campaignId}
            />
            {
                // ? Campaign analytic area
            }
            <div style={ContainerStyles.bottomContainer}>
                <Text css={ItemStyles.sectionTitle}>
                    Campaign Analytics
                </Text>
                <div style={ContainerStyles.filterContainer}>
                    {renderFilter()}
                </div>
                <div style={ContainerStyles.graphContainer}>
                    {/* <div style={ContainerStyles.graphTable}>
                        {qrList.length > 0 ? renderLineGraph() : ''}
                    </div> */}
                    <div style={ContainerStyles.graphTable}>
                        {qrList.length > 0 ? renderTotalCompletedSurveyLineGraph() : ''}
                    </div>
                    <div style={ContainerStyles.graphTable}>
                        {qrList.length > 0 ? renderBarGraph() : ''}
                    </div>
                </div>
                <div style={ContainerStyles.graphContainer}>
                    <div style={{ ...ContainerStyles.graphTable, width: '100%', maxWidth: 'none' }}>
                        {qrList.length > 0 ? renderTimeUsersCompleteSurveysLineGraph() : ''}
                    </div>
                </div>
                <div style={ContainerStyles.statsContainer}>
                    {qrList.length > 0 ? renderStats() : ''}
                </div>
                <div style={ContainerStyles.scanContainer}>
                    <div style={ContainerStyles.scanTable}>
                        {qrList.length > 0 ? renderScanOS() : ''}
                    </div>
                    <div style={ContainerStyles.scanTable}>
                        {qrList.length > 0 ? renderScanCities() : ''}
                    </div>
                </div>
            </div>

            <SidebarFilter
                campaignId={campaignId}
                uniqueUser={uniqueUser}
                startDate={startDate as string}
                endDate={endDate as string}
            />
        </div>
    );
};

const ping = keyframes`
    0% {
    transform: scale(1);
    opacity: 0.8;
    }
    80% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(2.2);
        opacity: 0;
    }
`;

const Indicator = styled.div`
    border: 0;
    border-radius: 50%;
    background-color: ${Colors.active};
    color: white;
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
`;

const IndicatorPing = styled.div`
    border: 0;
    border-radius: 50%;
    background-color: ${Colors.active};
    color: white;
    width: 18px;
    height: 18px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    animation: ${ping} 1s ease-in-out 1s infinite both;
    z-index: -5;
`;

const mapStateToProps = (state: RootState) => ({
    qrListLoading: Selectors.qrGetAllAttempting(state),
    qrListError: Selectors.qrGetAllError(state),
    qrList: Selectors.qrGetAll(state),
    campaignList: Selectors.campaignGetAll(state),
    createNewQrLoading: Selectors.qrCreateNewAttempting(state),
    isFilterSidebarOpen: Selectors.qrGetIsFilterSidebarOpen(state),
    startDate: Selectors.qrGetFilterStartDate(state),
    endDate: Selectors.qrGetFilterEndDate(state),
    getCampaignReportAttempt: Selectors.reportsGetCampaignReportsAttempting(state),
    getCampaignReportError: Selectors.reportsGetReportsError(state),
    campaignReport: Selectors.reportsGetCampaignReports(state),
    uniqueUser: Selectors.qrGetIsUniqueUser(state),
    getFilterByFlavour: Selectors.qrGetFilterByFlavour(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getAllQrs: (campaignId: string) => dispatch(Actions.getAllQrAttempt({ campaignId })),
    createNewQr: (campaignId: string) => dispatch(Actions.createNewQrAttempt({ campaignId })),
    resetQrDetails: () => dispatch(Actions.getQrDetailsReset()),
    resetQrSurveys: () => dispatch(Actions.getQrSurveysReset()),
    resetUniqueCodes: () => dispatch(Actions.getUniqueCodesReset()),
    resetSurveyData: () => dispatch(Actions.getAllSurveyReset()),
    setFilterSidebarOpen: (state: boolean) => dispatch(Actions.setFilterSidebarOpen(state)),
    setStartDate: (date: string | null) => dispatch(Actions.qrSetFilterStartDate(date)),
    setEndDate: (date: string | null) => dispatch(Actions.qrSetFilterEndDate(date)),
    getCampaignReport: (data: IGetCampaignReportParams) => dispatch(Actions.reportsGetCampaignReportsAttempt(data)),
    setUniqueUser: (state: boolean) => dispatch(Actions.setUniqueUser(state)),
    clearFilterByFlavour: () => dispatch(Actions.clearFilterByFlavour()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QrScreen);
